<template>

<footer class="text-white" style="background: var(--bs-success);">
    <div class="container py-4 py-lg-5" style="padding: 0 50px;">
        <div class="row">
            <div class="col"><img src="/assets/img/footer_ww_logo.png" style="padding: 50px 0;" width="100%" height="auto" /></div>
        </div>
        <div class="row justify-content-center">
            <div class="col"><img src="/assets/img/powered_by_abey2.png" style="width: 150px;margin: 0 0 20px 0;" /></div>
            <div class="col footlinks">
                <ul>
                    <!--li><router-link to="/news">{{$t('nav_news')}}</router-link></li-->
                    <li><router-link class="nav-link active text-center" to="/tokens">{{$t('nav_tokens')}}</router-link></li>
                    <li><router-link class="nav-link active text-center" to="/market">{{$t('nav_market')}}</router-link></li>
                    <li><a class="nav-link active text-center" :href="wp_url"  target="_blank">{{$t('nav_wp')}}</a></li>
                </ul>
            </div>
            <!--div class="col">
                <ul>
                    <li>{{$t('nav_news')}}</li>
                    <li>{{$t('nav_tokens')}}</li>
                    <li>{{$t('nav_market')}}</li>
                    <li>{{$t('nav_wp')}}</li>
                </ul>
            </div-->
            <div class="col">
                <div class="fw-bold text-end d-flex align-items-center mb-2">
                    <ul class="list-inline text-end mb-0" style="width: 100%;">
                        <li class="list-inline-item text-end">
                            <img class="social_icons" src="/assets/img/social_media/instagram.png" />
                            <img class="social_icons" src="/assets/img/social_media/x.png" />
                            <img class="social_icons" src="/assets/img/social_media/github.png" />
                        </li>
                    </ul>
                </div>
                <p class="text-end">2023 PIXELAKES, ALL RIGHTS RESERVED</p>
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-center pt-3"></div>
    </div>
</footer>


</template>

<style lang="scss">
    .footlinks{        
        display: flex;
        align-content: stretch;
        justify-content: center;
        flex-direction: column;

        ul{            
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-evenly;
            align-content: center;
        }

    }

</style>

<script>
export default {
    computed:{
        wp_url(){return process.env.VUE_APP_WP_URL;}
    }
}
</script>