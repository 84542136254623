<template>
<div>
  <div class="hero">
        <div class="container">
            <div class="row">
                <div class="col-md-8 col-xl-6 text-center mx-auto"><img src="/assets/img/ww_logo.png" style="width: 100%;">
                    <h1 class="display-6 fw-bold" style="text-shadow: 1px 1px 20px rgba(13,0,18,0.65);"><strong><span style="color: rgb(255, 255, 255);">{{ $t('homeview_heroTagLine') }}</span></strong></h1><img src="/assets/img/divider.png" style="width: 100%;padding: 35px 0 0 0;">
                    <FancyButton @click="StartDownload" :isDisabled="downloadInProgress" v-if="!isMobile" />
                </div>
            </div>
        </div>
    </div>
    <section class="brand_video clean-block features"><iframe allowfullscreen="" frameborder="0" :src="YouTubeUrl" width="560" height="315"></iframe></section>
    <div class="container cols overview">
        <div class="row gy-4 gy-md-0">
            <div class="col-md-6">
                <div class="p-xl-5 m-xl-5" style="/*margin: 50px;*//*padding: 50px;*/"><img class="rounded img-fluid w-100 fit-cover" style="min-height: 300px;" src="/assets/img/three_cards.png"></div>
            </div>
            <div class="col-md-6 d-md-flex align-items-md-center">
                <div style="max-width: 350px; padding-left:20px">
                    <h2 class="display-5 fw-bold"><strong>{{ $t('homeview_overview') }}</strong></h2>
                    <hr class="hr-purple-left">
                    <p class="my-3"><span style="color: rgb(44, 29, 75);">{{ $t('homeview_overview_text') }}</span></p>
                </div>
            </div>
        </div>
    </div>
    <div class="container how-to-play">
        <div class="row mb-5">
            <div class="col-12 col-md-8 col-lg-8 col-xl-6 offset-md-2 offset-lg-2 offset-xl-3 offset-xxl-3 text-center">
                <h2 class="display-5 fw-bold">{{ $t('how_to_play') }}</h2>
                <hr class="d-flex justify-content-center hr-purple-center">
            </div>
        </div>
        <div class="row gy-4 row-cols-1 row-cols-md-2 row-cols-xl-3">
            <div class="col">
                <div class="text-center d-flex flex-column align-items-center align-items-xl-center"><img src="/assets/img/easy_to_learn_icon.png" style="width: 140px;margin: 20px;">
                    <div class="px-3">
                        <h3 style="color: var(--bs-success);" v-html="$t('easy_to_learn')"></h3>
                        <p style="margin: 20px 0px;">{{ $t('easy_to_learn_text') }}</p>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="text-center d-flex flex-column align-items-center align-items-xl-center"><img src="/assets/img/deck_icon.png" style="width: 140px;margin: 20px;">
                    <div class="px-3">
                        <h3 style="color: var(--bs-success);" v-html="$t('deck_building')"></h3>
                        <p style="margin: 20px 0px;">{{$t('deck_building_text')}}</p>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="text-center d-flex flex-column align-items-center align-items-xl-center"><img src="/assets/img/buttle_icon.png" style="width: 140px;margin: 20px;">
                    <div class="px-3">
                        <h3 style="color: var(--bs-success);" v-html="$t('pvp_battles')"></h3>
                        <p style="margin: 20px 0px;">{{$t('pvp_battles_text')}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section class="play-and-earn clean-block">
        <div class="container py-4 py-xl-5" style="/*margin-bottom: 0;*/">
            <div class="row" style="width: 100%;margin: auto;/*margin-bottom: 150px;*//*margin-top: 150px;*/">
                <div class="col-md-8 col-xl-8 col-xxl-7 text-center mx-auto">
                    <h2 class="display-5 fw-bold" style="color: #fff;">{{ $t('homeview_play_earn') }}</h2>
                    <hr class="hr-white-center"><img src="/assets/img/powered_by_abey1.png" style="width: 180px;padding: 20px 0;">
                    <h4 class="text-center"><span style="color: rgb(250, 248, 255);">{{ $t('homeview_play_earn_text') }}</span></h4>
                    <div class="d-flex align-items-center align-items-md-start align-items-xl-center" style="padding: 40px 0;">
                        <div class="bs-icon-xl bs-icon-circle bs-icon-primary d-flex flex-shrink-0 stone"><img src="/assets/img/glostone.png" style="width: 100%;"></div>
                        <div class="text-start">
                            <h3 style="color: rgb(255,255,255);">Glöstöne</h3>
                            <p style="color: var(--bs-info-bg-subtle);">$({{ GloSymbol }})</p>
                            <p style="color: rgb(255,255,255);">{{ $t('homeview_glo_text') }}</p>
                            <!-- a class="btn btn-primary active btn_arrows" role="button" href="/glöstöne.html"><img src="/assets/img/arrow_left.png">GET TOKEN<img src="/assets/img/arrow_right.png"></a-->
                            <ImportGloBtn  />
                        </div>
                    </div>
                    <div class="d-flex align-items-center align-items-md-start align-items-xl-center" style="padding: 40px 0;">
                        <div class="bs-icon-xl bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center stone"><img src="/assets/img/hex.png" style="width: 100%;"></div>
                        <div class="text-start">
                            <h3 style="color: rgb(255,255,255);">Hex Ore</h3>
                            <p style="color: var(--bs-info-bg-subtle);">$({{ HexSymbol }})</p>
                            <p style="color: rgb(255,255,255);">{{ $t('homeview_hex_text') }}</p>
                            <!-- a class="btn btn-primary active btn_arrows" role="button" href="/glöstöne.html"><img src="/assets/img/arrow_left.png">GET TOKEN<img src="/assets/img/arrow_right.png"></a-->
                            <ImportHexBtn  />
                        </div>
                    </div>
                    <div class="text-start d-flex align-items-center align-items-md-start align-items-xl-center" style="padding: 40px 0;">
                        <div class="bs-icon-xl bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center stone"><img src="/assets/img/nft_card.png" style="width: 100%;"></div>
                        <div>
                            <h3 style="color: rgb(255,255,255);">{{ $t('homeview_nft') }}</h3>
                            <p style="color: rgb(255,255,255);">{{ $t('homeview_nft_text') }}</p>
                            <router-link class="btn btn-primary active btn_arrows" role="button" to="/market"><img src="/assets/img/arrow_left.png">{{ $t('get_nfts') }}<img src="/assets/img/arrow_right.png"></router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="paroxys">
        <div class="container py-4 py-xl-5">
            <div class="row mb-5">
                <div class="col-md-8 col-xl-7 col-xxl-7 text-center mx-auto">
                    <h2 class="display-5 fw-bold" style="color: #fff;">{{ $t('homeview_delve') }}</h2>
                    <hr class="hr-white-center">
                    <h4 class="w-lg-50" style="color: var(--bs-light);">{{ $t('homeview_delve_text') }}</h4>
                </div>
            </div>
            <div class="row gy-4 row-cols-1 row-cols-md-2 row-cols-xl-3 paroxys-card">
                <div class="col paroxys-card-col">
                    <div class="card"><img class="card-img-top w-100 d-block fit-cover" src="/assets/img/empire.png" width="297" height="590">
                        <div class="card-body p-4" style="border-bottom-style: none;">
                            <h3 class="card-title" v-html="$t('homeview_glorpan')"></h3>
                            <p class="card-text">{{ $t('homeview_glorpan_text') }}</p>
                        </div>
                    </div>
                </div>
                <div class="col paroxys-card-col">
                    <div class="card" style="background: transparent;"><img class="card-img-top w-100 d-block fit-cover" src="/assets/img/undercreeps.png">
                        <div class="card-body p-4">
                            <h3 class="card-title" v-html="$t('homeview_undercreep')"></h3>
                            <p class="card-text">{{ $t('homeview_undercreep_text') }}</p>
                        </div>
                    </div>
                </div>
                <div class="col paroxys-card-col">
                    <div class="card" style="background: transparent;"><img class="card-img-top w-100 d-block fit-cover" src="/assets/img/hydra.png">
                        <div class="card-body p-4">
                            <h3 class="card-title" v-html="$t('homeview_hydra')"></h3>
                            <p class="card-text">{{ $t('homeview_hydra_text') }}</p>
                        </div>
                    </div>
                </div>
                <div class="col paroxys-card-col">
                    <div class="card" style="background: transparent;"><img class="card-img-top w-100 d-block fit-cover" src="/assets/img/goat.png">
                        <div class="card-body p-4">
                            <h3 class="card-title" v-html="$t('homeview_goat')"></h3>
                            <p class="card-text paroxys-card-text">{{ $t('homeview_goat_text') }}</p>
                        </div>
                    </div>
                </div>
                <div class="col paroxys-card-col">
                    <div class="card" style="background: transparent;"><img class="card-img-top w-100 d-block fit-cover" src="/assets/img/cloudpeak.png">
                        <div class="card-body p-4">
                            <h3 class="card-title">{{ $t('homeview_cloudpeak') }}</h3>
                            <p class="card-text paroxys-card-text">{{ $t('homeview_cloudpeak_text') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <BuildSection />
    <!--section class="build" style="background: var(--bs-info);">
        <div class="container py-4 py-xl-5">
            <div class="row mb-5">
                <div class="col-md-8 col-xl-6 text-center mx-auto">
                    <h2 class="display-5 fw-bold">{{ $t('homeview_build') }}</h2>
                    <hr class="hr-purple-center">
                    <h4 v-html="$t('homeview_build_text')"></h4>
                </div>
            </div>
            <div class="row gy-4 row-cols-1 row-cols-md-2 row-cols-xl-3">
                <div class="col">
                    <div class="text-center d-flex flex-column align-items-center align-items-xl-center"><img src="/assets/img/market_icon.png" style="width: 150px;margin: 20px;">
                        <h3>{{$t('market')}}</h3><a class="btn btn-primary active btn_1" role="button" href="/glöstöne.html">{{$t('market_place')}}</a>
                    </div>
                </div>
                <div class="col">
                    <div class="text-center d-flex flex-column align-items-center align-items-xl-center"><img src="/assets/img/dev_icon.png" style="width: 150px;margin: 20px;">
                        <h3>{{$t('dev')}}</h3><a class="btn btn-primary btn_1" role="button" href="#">{{$t('coming_soon')}}</a>
                    </div>
                </div>
                <div class="col">
                    <div class="text-center d-flex flex-column align-items-center align-items-xl-center"><img src="/assets/img/doc_icon.png" style="width: 150px;margin: 20px;">
                        <h3>{{$t('docs')}}</h3><a class="btn btn-primary btn_1" role="button" href="#">{{$t('coming_soon')}}</a>
                    </div>
                </div>
            </div>
        </div>
    </section-->
</div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

import FancyButton from '../components/fancyButton.vue'
import ImportGloBtn from '../components/tokenImport/gloImportButton.vue'
import ImportHexBtn from '../components/tokenImport/hexImportButton.vue'
import BuildSection from '../components/buildSection.vue'
export default {
    components:{FancyButton,ImportHexBtn,ImportGloBtn,BuildSection},
    computed:{        
        GloSymbol() {return process.env.VUE_APP_GLO_SYMBOL},    
        HexSymbol() {return process.env.VUE_APP_HEX_SYMBOL},
        YouTubeUrl() {return `https://www.youtube.com/embed/${this.videoId}?playlist=${this.videoId}&loop=${this.loop}&autoplay=${this.autoplay}&mute=${this.mute}&controls=${this.controls}&rel=${this.rel}`}  
    },
    data(){
        return {
            // youtube video
            videoId:"me9LEWA4DYU",
            loop:1,
            autoplay:1,
            mute:1,
            controls:0,
            rel:0,
            downloadInProgress:false,
            isMobile: false
        }
    },
    mounted() {
        this.isMobile = /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    methods:{
        async StartDownload(){
            if(this.downloadInProgress){return;}
            try{
                this.downloadInProgress=true;
                let response = await fetch('https://stuzy2lql2.execute-api.us-east-1.amazonaws.com/testing/game/download',{method:'GET'});
                if(response.ok){
                    const dl_url = await response.json();
                    console.log(dl_url.data);
                    window.open(dl_url.data);
                    
                }else{
                    alert("there was an error getting a download link, pelase try again")
                }
            }catch(e){
                this.downloadInProgress=false;
            }
            this.downloadInProgress=false;
        }
    }

}
</script>
