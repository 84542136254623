var g,h,c,l,p,u;
let net = "local";
/*if(process.env.CHAIN_NETWORK=='rinkeby'){ net = "local";} */

g = require(`./${net}/glo-abi.json`);
h = require(`./${net}/hex-abi.json`);
c = require(`./${net}/wtc-abi.json`);
l = require(`./${net}/loot-abi.json`);
p = require(`./${net}/ico-abi.json`);
u = require(`./${net}/usdt-abi.json`);

export const CONTRACT_GLO       = g; // GLO Stone
export const CONTRACT_HEX       = h; // Hex Ore
export const CONTRACT_WTC       = c; // NFT Card
export const CONTRACT_LOOTBOX   = l; // Loot Box sale
export const CONTRACT_ICO       = p; // Public Sale ICO
export const CONTRACT_USDT      = u; // USDT On ABEY
